<!-- 小程序用户管理 -->
<template>
    <div class="outContainer">
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-input class="each" placeholder="用户昵称" v-model="searchInfo.param.name"></el-input>
        <el-input class="each" placeholder="联系电话" v-model="searchInfo.param.phone"></el-input>
        <el-select class='each' placeholder="是否为内部员工" v-model="searchInfo.param.isAdmin" clearable>
          <el-option
            v-for="item in isAdminOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="hanldleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="hanldleSearch('clear')">清空搜索条件</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableContainer">
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column align="center" label="昵称" prop="name">
          </el-table-column>
          <el-table-column align="center" label="联系电话" prop="phone">
          </el-table-column>
          <el-table-column align="center" label="是否为内部员工" prop="isAdmin">
            <template slot-scope="scope">
              {{ scope.row.isAdmin && scope.row.isAdmin != '--' ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="internalStaff(scope.row,true)" v-if="!scope.row.isAdmin || scope.row.isAdmin == '--'">设置内部员工</el-button>
              <el-button size="mini" @click="internalStaff(scope.row,false)" v-else>取消内部员工</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
    </div>
  </template>
  
  <script>
  import pagination from '@/components/Pagination.vue';
  import { getWechatUserList,settingAdmin } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
        searchInfo:{ // 筛选条件
          pageNo:1,
          pageSize:10,
          total:1,
          param:{
            name:null,
            phone:null,
            isAdmin:null,
          },
        },
        tableData: [], // 表格数据
        isAdminOptions:[ // 是否为内部员工选项
          {value:true,label:'是'},
          {value:false,label:'否'},
        ]
      }
    },
  
    components: {
      pagination
    },
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      // 筛选事件
      hanldleSearch(type){
        this.searchInfo.pageNo = 1;
        if(type == 'clear'){
          this.searchInfo.param = {};
        }else{
          this.searchInfo.param.name = this.searchInfo.param.name == "" ? null : this.searchInfo.param.name;
          this.searchInfo.param.phone = this.searchInfo.param.phone == "" ? null : this.searchInfo.param.phone;
        }
        this.init();
      },
      // 初始化获取表格数据
      async init(){
        await getWechatUserList(this.searchInfo).then(res=>{
          if(res.success){
            this.tableData = res.data.list;
            this.searchInfo.total = Number(res.data.total);
            this.tableData.forEach(element => {
              element.createTimeStr = timeChange(element.createTime,'seconds');
              for(let key in element){
                if((!element[key] && element[key]!=0) || element[key] == ""){
                  element[key]='--';
                }
              }
            });
          }else{
            this.tableData = [];
            this.searchInfo.total = 0;
          }
        })
      },
      /**
       * 设置、取消该用户为内部员工
       * @param {Object} row 当前操作用户的信息对象
       * @param {Number} type 设置类型 true 设置为内部员工 false 取消内部员工
       */
      internalStaff(row,type){
        this.$confirm('确认进行该操作吗？').then( async ()=>{
          let data = {
            param: {
              id: row.id,
              isAdmin: type,
            }
          }
          await settingAdmin(data).then(res=>{
            if(res.success){
              this.$message({
                message:'操作成功',
                type:'success',
              })
            }else{
              this.$message({
                message:res.msg,
                type:'fail',
              })
            }
            this.init();
          })
        })
      },
      // 分页插件事件
      callFather(parm) {
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped></style>
  